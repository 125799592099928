import DraggableWindow from "./DraggableWindow";
import Icon from "./Icon";
import file from "../images/icons/text-file.png"
import { useState } from "react";
import { useDispatch } from 'react-redux';
import { setShowTextEditor, addActiveApp, addAppOnTop} from '../store';

function ProjectsWindow({ label, onXClick, maxDimensions }) {
    const [selectedIcon, setSelectedIcon] = useState("read.me");
    const dispatch = useDispatch()


    const handleDoubleClick = (icon) => () => {
        dispatch(addActiveApp("Text Editor"))
        dispatch(addAppOnTop("Text Editor"))
        if (icon === "read.me") {
            dispatch(setShowTextEditor(true))
        }
    }

    return (
        <DraggableWindow windowId="projects" maxDimensions={maxDimensions} onXClick={onXClick} label={label}>

            <div className="filesContainer">
                <Icon
                    isSelected={selectedIcon === "read.me"}
                    label="read.me" icon={file}
                    iconWidth={60}
                    iconHeight={60}
                    onDoubleClick={handleDoubleClick('read.me')}
                />
            </div>
        </DraggableWindow>

    );
}

export default ProjectsWindow