import { useSelector, useDispatch } from 'react-redux';
import { setNavbarPosition, setShowSeconds } from '../store';
import { useState } from 'react';

function MiscSettings() {
    const [isFullscreen, setIsFullscreen] = useState(false);

    const dispatch = useDispatch();
    const theme = useSelector((state) => {
        return state.themeSettings.theme;
    });
    const miscSettings = useSelector((state) => {

        return state.miscSettings
    })


    const handleNavbarPositionClick = (position) => {
        dispatch(setNavbarPosition(position))
    }
    function is_fullscreen() {
        return document.fullscreenElement != null;
    }

    const handleSecondsClick = () => {
        dispatch(setShowSeconds(!miscSettings.showSeconds))
    }


    const openFullscreen = () => {
        var elem = document.body;
        if (is_fullscreen() === false) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
                setIsFullscreen(true);
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
                setIsFullscreen(true);
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
                setIsFullscreen(true);
            }
        }
        else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullscreen(false);
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
                setIsFullscreen(false);
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
                setIsFullscreen(false);
            }
        }
    }

    return (<>
        <div className={`miscSetting nes-container ${theme === "dark" ? "is-dark" : "is-light"}`}><label>
            <input type="checkbox" className={`nes-checkbox ${theme === "dark" ? "is-dark" : "is-light"}`} checked={miscSettings.showSeconds ? true : false} onChange={handleSecondsClick} />
            <span>Show Clock Seconds</span>
        </label></div>

        <div className={`miscSetting nes-container ${theme === "dark" ? "is-dark" : "is-light"}`}><label>
            <input type="checkbox" className={`nes-checkbox ${theme === "dark" ? "is-dark" : "is-light"}`} checked={isFullscreen ? true : false} onChange={openFullscreen} />
            <span>Make app fullscreen</span>
        </label></div>
        <div className={`miscSetting nes-container ${theme === "dark" ? "is-dark" : "is-light"}`}>
            Navigation Bar Position:
            <label>
                <input onChange={() => handleNavbarPositionClick("top")} type="radio" className="nes-radio" name="answer-dark" checked={miscSettings.navbarPosition === "top" ? true : false} />
                <span>Top</span>
            </label>

            <label>
                <input onChange={() => handleNavbarPositionClick("bottom")} type="radio" className="nes-radio" name="answer-dark" checked={miscSettings.navbarPosition === "bottom" ? true : false} />
                <span>Bottom</span>
            </label>
        </div></>
    )

}

export default MiscSettings
