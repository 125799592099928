import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { setBackground } from "../store";

function GalleryImage({ image, index }) {
    const [selected, setSelected] = useState(false)
    const [imageLoading, setImageLoading] = useState(true);
    const [pulsing, setPulsing] = useState(true);

    const theme = useSelector((state) => {
        return state.themeSettings.theme;
    });

    const imageLoaded = () => {
        setImageLoading(false);
        setTimeout(() => setPulsing(false), 600);
    };


    const dispatch = useDispatch();

    const handleClick = () => {
        setSelected(true)
        dispatch(setBackground(image))
    }

    useEffect(() => {
        if (selected) {
            setTimeout(() => {
                setSelected(false);
            }, 1500);
        }
    }, [selected]);

    return (
        <div className={`${pulsing ? "pulse" : ""} loadable gallery-image`}>

            <motion.img
                initial={{ opacity: 0 }}
                animate={{
                    opacity: imageLoading ? 0 : 1
                }}

                onClick={handleClick}
                transition={
                    (
                        { opacity: { delay: 0.2, duration: 0.3 } },
                        { duration: 0.3 }
                    )
                }
                onLoad={imageLoaded}
                key={index}
                src={image}
                whileHover={{ scale: 1.5 }}
                whileTap={{ scale: 0.8 }}
                style={{ width: '100%', height: '100%', padding: "0px", borderStyle: "solid", borderWidth: "4px", borderColor: selected ? "green" : theme === "dark" ? "white" : "black" }}
                loading="lazy"
                alt="gallery image"
            />

        </div>
    )
}

export default GalleryImage