import { configureStore, createSlice } from '@reduxjs/toolkit'


const activeAppsSlice = createSlice({
    name: 'activeApps',
    initialState: [],
    reducers: {
        addActiveApp(state, action) {
            if (!state.includes(action.payload)) {
                state.push(action.payload);
            }
        },
        removeActiveApp(state, action) {
            state.splice(state.indexOf(action.payload), 1)
        },

        removeAllActiveApps(state) {
            return []; 
        },
    }
})

const AppOnTopSlice = createSlice({
    name: 'appOnTop',
    initialState: "",
    reducers: {
        addAppOnTop(state, action) {
            return action.payload;

        }
    }
})

const showAppSlice = createSlice({
    name: 'showTextEditor',
    initialState: false,
    reducers: {
        setShowTextEditor(state, action) {
            return action.payload;
        }
    }
})

const ThemeSettingsSlice = createSlice({
    name: 'themeSettings',
    initialState: {
        theme: "dark",
        background: "https://i.redd.it/vrfm1irroql01.png",
    },
    reducers: {
        setTheme(state, action) {
            state.theme = action.payload;
        },
        setBackground(state, action) {
            state.background = action.payload;
        }

    }
})

const miscSettingsSlice = createSlice({
    name: 'miscSettings',
    initialState: {
        showSeconds: false,
        navbarPosition: "bottom",
    },
    reducers: {
        setShowSeconds(state, action) {
            state.showSeconds = action.payload;
        },
        setNavbarPosition(state, action) {
            state.navbarPosition = action.payload;
        }

    }
})

const filesSlice = createSlice({
    name: 'files',
    initialState: [{
        name:"read.me",
        content:`
╭━━━╮╱╱╱╱╱╱╱╱╱╱╱╭━━━┳━━━╮
┃╭━╮┃╱╱╱╱╱╱╱╱╱╱╱┃╭━╮┃╭━╮┃
┃╰━━┳━━┳━┳━━┳┳╮╭┫┃╱┃┃╰━━╮
╰━━╮┃┃━┫╭┫╭╮┣┫┃┃┃┃╱┃┣━━╮┃
┃╰━╯┃┃━┫┃┃╰╯┃┃╰╯┃╰━╯┃╰━╯┃
╰━━━┻━━┻╯╰━╮┣┻━━┻━━━┻━━━╯
╱╱╱╱╱╱╱╱╱╭━╯┃╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╰━━╯╱╱╱╱╱╱╱╱╱╱╱╱

==What's this?====================
This is an operating system mock-up built in React.

==What functions does it have?====
- Draggable and resizable windows
- Terminal
- Text editor
- Web browser
- Theme changer
- Wallpaper changer
        `
    }],
    reducers: {
        addFile(state, action) {
            state.push(action.payload);
        },
        updateFile(state, action) {
            const { fileName, content } = action.payload;
            const file = state.find(file => file.name === fileName);
            if (file) {
                file.content = content;
            }
        }
    }
})

const store = configureStore({
    reducer: {
        activeApps: activeAppsSlice.reducer,
        appOnTop: AppOnTopSlice.reducer,
        themeSettings: ThemeSettingsSlice.reducer,
        miscSettings: miscSettingsSlice.reducer,
        showTextEditor: showAppSlice.reducer,
        files: filesSlice.reducer

    }
})


export { store };
export const { addActiveApp, removeActiveApp, removeAllActiveApps } = activeAppsSlice.actions;
export const { addAppOnTop } = AppOnTopSlice.actions
export const { setBackground, setTheme } = ThemeSettingsSlice.actions
export const { setShowSeconds, setNavbarPosition } = miscSettingsSlice.actions;
export const { setShowTextEditor } = showAppSlice.actions
export const { addFile, updateFile } = filesSlice.actions
