import SetBackgroundSetting from './SetBackgroundSetting';
import ThemePickerSetting from './ThemePickerSetting';
import { useSelector, useDispatch } from 'react-redux';
import MiscSettings from './MiscSettings';

const Settings = () => {

  const theme = useSelector((state) => {
    return state.themeSettings.theme;
  });


  return (
    <div id='settings' className={`nes-container ${theme === "dark" ? "is-dark" : "is-light"}`}>
      <h1>Settings Page</h1>

      <div className='settingsContainer'>
        <SetBackgroundSetting />
        <div className={`setting nes-container ${theme === "dark" ? "is-dark" : "is"}`} style={{ padding: 0 }}>
          <ThemePickerSetting />
        </div>
        <div className={`setting miscSettings nes-container ${theme === "dark" ? "is-dark" : ""}`}>
          <MiscSettings />
        </div>
        <div className={`setting miscSettings nes-container ${theme === "dark" ? "is-dark" : ""}`}>
          <div class="links-container">
            <h3>Links</h3>
            <section class="icon-list">
              <a rel="noreferrer" target='_blank' href="https://github.com/SergiuDumitru"><i class="nes-icon github is-medium"></i></a>
              <a rel="noreferrer" target='_blank' href="https://www.linkedin.com/in/sergiudumitru/"><i class="nes-icon linkedin is-medium"></i></a>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;