import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBackground } from '../store';
import ImageUploading from 'react-images-uploading';
import Modal from './Modal';
import gallery from '../images/icons/gallery.png';

function SetBackgroundSetting() {
    const [images, setImages] = useState([]);
    const [background, setBackgroundState] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [alertFields, setAlertFields] = useState(false);

    const [showBackgroundModal, setShowBackgroundModal] = useState(false);

    const theme = useSelector((state) => {
        
      return state.themeSettings.theme
    })
  
    const maxNumber = 1;
  
    const onChange = (imageList, addUpdateIndex) => {
      // data for submit
      setImages(imageList);
    };
  
  
    // pana aici
  
    const dispatch = useDispatch();
    const handleChange = (event) => {
      setAlertFields(false);
      setBackgroundState(event.target.value);
    };
  
    const isValidUrl = (string) => {
      try {
        new URL(string);
        return true;
      } catch (_) {
        return false; 
      }
    };
  
    const handleSubmit = () => {
      if (background && isValidUrl(background)) {
        dispatch(setBackground(background));
        setIsSuccess(true);
      } else if (images.length > 0 && images[0].data_url) {
        dispatch(setBackground(images[0].data_url));
        setIsSuccess(true);
      } else {
        setAlertFields(true);
      }
      
  
    };
  
    const clearBackgroundLink = () => {
      setBackgroundState('');
    };

    const handleClick = () => {
      setShowBackgroundModal(true);
    };

    const handleClose = () => {
      setShowBackgroundModal(false);
    };

    const actionBar = (
      <div>
        <button onClick={handleClose} className='nes-btn is-error modal-close'>
          X
        </button>
      </div>
    );

    const modal = (
      <Modal onClose={handleClose} actionBar={actionBar} >
        <p>Background Gallery</p>
        
      </Modal>
    );

    
    //effects
  
    useEffect(() => {
      if (isSuccess) {
        setTimeout(() => {
          setIsSuccess(false);
        }, 1500);
      }
    }, [isSuccess]);
  
    useEffect(() => {
      if(images.length > 0 && images[0].data_url){
        setAlertFields(false);
      }
    }, [images, background]);
  
    useEffect(() => {
    
      if (background && !isValidUrl(background)) {
        setAlertFields(true);
      } else {
        setAlertFields(false);
      }
    }, [background]);

    return (
        <div className={`setting nes-container ${theme === "dark" ? "is-dark" : ""}`}>
          {showBackgroundModal && modal}
          <h3>Change Desktop Background</h3>
          <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
            <input value={background} onChange={handleChange} type="text" className={`nes-input ${theme === "dark" ? "is-dark" : ""} ${alertFields ? 'is-error':""}`} placeholder="Background Link" />
            <button onClick={clearBackgroundLink} className='nes-btn is-error'>x</button>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ backgroundImage: `url(${background || images[0]?.data_url})` }} id="backgroundPreviewContainer" className={`nes-container ${theme === "dark" ? "is-dark" : ""}`}>
              {background || images[0]?.data_url ? '' : 'Preview...'}
            </div>
            <div className='buttons-container' style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <ImageUploading
                multiple
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div>
                    <button
                      className={`nes-btn ${alertFields ? 'is-error':""}`}
                      style={{ ...isDragging ? { color: 'red' } : {}, width: "100%" }}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Click or Drop here
                    </button>
                  

                    {imageList.map((image, index) => ( //map poate devine slideshow?
                      <div key={index} className="image-item">
                        {/* <img src={image['data_url']} alt="" width="100" /> */}
                        <div className="image-item__btn-wrapper">
                          <button onClick={() => onImageUpdate(index)}>Update</button>
                          <button onClick={() => onImageRemove(index)}>Remove</button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>
              <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
              <button onClick={handleSubmit} type="button" className={`nes-btn ${isSuccess ? 'is-success':""}`}>Update Background</button>
              <button onClick={handleClick} type="button" className="nes-btn"><img src={gallery} style={{width: "30px"}} alt="background gallery"/></button>
              </div>
            </div>
          </div>
        </div>
    );
}  

export default SetBackgroundSetting