import { useState } from 'react';
import Terminal, { ColorMode, TerminalOutput } from 'react-terminal-ui';
import TerminalOutputLine from './TerminalOutputLine';
import 'ldrs/ring'
import { mirage } from 'ldrs';
import { useSelector, useDispatch } from 'react-redux';
import { setTheme } from '../store';

function Console() {
    const [terminalLineData, setTerminalLineData] = useState([
        <TerminalOutput key={Math.random()}>"Type 'help' for a list of commands"</TerminalOutput>
    ]);

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const theme = useSelector((state) => {

        return state.themeSettings.theme
    })

    mirage.register()

    const url = "https://official-joke-api.appspot.com/random_joke";

    const loadNewJoke = async () => {
        setIsLoading(true);
        const request = await fetch(url).then((response) => response.json());

        setTerminalLineData((oldArray) => [...oldArray, <TerminalOutputLine 
            key={Math.random()}
            command="joke" 
            color={"green"} 
            text={request.setup} 
            />, 
            <TerminalOutputLine 
            key={Math.random()}
            color={"lightGreen"} 
            text={request.punchline} 
            />]);
        setIsLoading(false);
    };

    const handleConsoleCommand = (command) => {
        switch (command) {
            case "help":
                setTerminalLineData(oldArray => [...oldArray, <TerminalOutputLine
                    key={Math.random()}
                    command="help"
                    text={<p style={{ color: theme === "dark" ? "lightGreen" : "darkGreen" }}>Available commands: <br /> reboot <br /> clear <br /> joke <br /> setTheme_dark <br /> setTheme_light</p>} />])
                break;
            case "reboot":
                setIsLoading(true);
                setTerminalLineData(oldArray => [...oldArray, <TerminalOutputLine
                    key={Math.random()}
                    command="reboot"
                    color="red"
                    text="Rebooting..." />]);
                window.location.reload();
                break;
            case "clear":
                setTerminalLineData([]);
                break;
            case "joke":

                loadNewJoke();
                break;
            case "setTheme_dark":
                setTerminalLineData(oldArray => [...oldArray, <TerminalOutputLine
                    key={Math.random()}
                    command="setTheme_dark"
                    color={"lightBlue"}
                    text="Theme set to Dark" />])
                dispatch(setTheme("dark"));
                break;
            case "setTheme_light":
                setTerminalLineData(oldArray => [...oldArray, <TerminalOutputLine
                    key={Math.random()}
                    command="setTheme_light"
                    color={'darkBlue'}
                    text="Theme set to Light" />])
                dispatch(setTheme("light"));
                break;
            default:
                setTerminalLineData(oldArray => [...oldArray, <TerminalOutputLine
                    key={Math.random()}
                    command={command}
                    color={"red"}
                    text="Command not found" />]);
                break;
        }
    };
    return (
        <div id="console" className="container">
            <Terminal prompt='𐌔' colorMode={theme === "dark" ? ColorMode.Dark : ColorMode.Light} onInput={handleConsoleCommand}>
                {terminalLineData}{isLoading && <l-mirage
                    size="60"
                    speed="2.5"
                    color="white"
                ></l-mirage>}
            </Terminal>
        </div>
    );
}
export default Console;
