import ReactDOM from 'react-dom';
import { useEffect } from 'react';
import GalleryImage from './GalleryImage';
import { useSelector } from 'react-redux';

const images = require.context('../images/background-gallery', false);


function Modal({ onClose, children, actionBar }) {

  const theme = useSelector((state) => {
        
    return state.themeSettings.theme
  })

  useEffect(() => {
    document.body.classList.add('overflow-hidden');

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const imageList = images.keys().map(image => images(image));


  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={onClose}>
      <div 
      className={`modal-content nes-container ${theme === "dark" ? "is-dark" : ""}`} 
      onClick={e => e.stopPropagation()}
      style={{background: theme === "light" ? "white" : ""}}>
        <div className="modal-header">
          {actionBar}
        </div>
        <div className="modal-body">
          {children}
          <div className='gallery-images'>
            {imageList.map((image, index) => (
              <GalleryImage key={index} image={image} />
            ))}
          </div>
        </div>
      </div>
    </div>,
    document.querySelector('.modal-container')
  );
}

export default Modal;
