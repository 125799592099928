import React from 'react';
import Navbar from './components/Navbar';
import Main from './components/Main';
import { useState, useEffect } from 'react';



const App = () => {
  const [view, setView] = useState('desktop'); // 'desktop' or 'settings'
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1175 || window.innerHeight < 870);



  const MobileComponent = () => {
    return <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>Viewport too small</div>;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1175 || window.innerHeight < 870); // Update isMobile based on window width
    };

    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={"parent-container"}>
      {isMobile ? <MobileComponent /> : (
        <>
          <Main view={view} />
          <Navbar setView={setView} />
        </>
      )}
    </div>
  );
};

export default App;