import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

const Icon = ({ label, icon, iconWidth, iconHeight, isSelected, onClick, onDoubleClick }) => {
  const theme = useSelector((state) => {
        
    return state.themeSettings
  })
  return (<div onDoubleClick={onDoubleClick}> 
    <div className={`icon ${isSelected ? 'icon-selected pixel-corners' : ''} ${theme.theme === "dark" ? "icon-dark" : "icon-light"}` } onClick={onClick}>
      <img width={iconWidth} height={iconHeight} src={icon} alt={label} />  
      <p style={{color: theme.theme === "dark" ? "white" : "black"}}>{label}</p>
    </div></div>
  );
};

export default Icon;