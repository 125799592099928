import { FaCog } from 'react-icons/fa'
import SearchBar from './SearchBar';
import Clock from './Clock';
import { useSelector, useDispatch } from 'react-redux';
import ActiveApps from './ActiveApps';
import { removeAllActiveApps, setShowTextEditor } from '../store';

const Navbar = ({ setView }) => {

    const dispatch = useDispatch()

    const theme = useSelector((state) => {
        return state.themeSettings.theme
    })

    const showSecondsSetting = useSelector((state) => {
        return state.miscSettings.showSeconds
    })

    const navbarPosition = useSelector((state) => {
        return state.miscSettings.navbarPosition
    })

    const activeApps = useSelector((state) => {
        return state.activeApps
    })


    const handleSettingsClick = () => {
        dispatch(removeAllActiveApps())
        dispatch(setShowTextEditor(false))

        setView('settings')
    }

    return (
        <div id="navbar" className={`nes-container is-rounded ${navbarPosition} ${theme === "dark" ? "is-dark" : "smaller is-light"}`}>
            <div id="navLeft">
                <button onClick={() => setView('desktop')} type="button" className="nes-btn" style={{ background: theme === "dark" ? "white" : "black", color: theme === "dark" ? "black" : "white" }}>Desktop</button>
                <SearchBar />
                <ActiveApps activeApps={activeApps} />
                
            </div>
            <div id="navRight">
                <button onClick={handleSettingsClick} type="button" className="nes-btn"><FaCog size={18} /></button>
                <Clock showSeconds={showSecondsSetting} />
            </div>

        </div>
    );
};

export default Navbar;
