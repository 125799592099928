import { motion, useMotionValue, useTransform } from "framer-motion";
import { useSelector, useDispatch } from 'react-redux';
import { setTheme } from '../store';

const ThemePickerSetting = () => {

    const dispatch = useDispatch();

    const theme = useSelector((state) => {
        return state.themeSettings.theme;
    });

    const nightSky = "rgb(7,0,88)"
    const daySky = "rgb(51, 70, 255)"
    const sun = "rgb(253, 184, 19)"
    const moon = "rgb(246, 241, 213)"

    const x = useMotionValue(0);
    const background = useTransform(
        x,
        [-100, 0, 100],
        [nightSky, theme === "light" ? daySky : nightSky, daySky]
    );

    const ball = useTransform(
        x,
        [-100, 0, 100],
        [moon, theme === "light" ? "rgb(255,255,255)" : "rgb(0,0,0)", sun]
    );
    return (
        <motion.div
            style={{
                overflow: "hidden",
                width: "100%",
                height: "100%",
                backgroundColor: background,
                display: "flex",
                placeItems: "center",
                placeContent: "center"
            }}
        >
            <motion.div
                style={{
                    width: 150,
                    height: 150,
                    borderRadius: 75,
                    backgroundColor: ball,
                    x,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
                drag="x"
                dragConstraints={{ right: 0, left: 0 }}
                whileTap={{ scale: 0.95 }} // Example of animating scale instead of className
                onDragEnd={() => {
                    if (x.get() > 0) {
                        dispatch(setTheme("light"));
                    } else {
                        dispatch(setTheme("dark"));
                    }
                    x.set(0);
                }}
                className="nes-pointer" // Apply the class directly here
            >
                <span style={{ color: theme === "light" ? "black" : "white", fontSize: 10 }}>&lt;Dark/Light&gt;</span>
            </motion.div>
        </motion.div>
    )
}

export default ThemePickerSetting