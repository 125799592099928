import DraggableWindow from "./DraggableWindow"
import { useSelector, useDispatch } from "react-redux"
import { updateFile } from '../store'
import { useState, useEffect } from "react";

function TextEditor({ label, maxDimensions, onXClick, fileName }) {
    const dispatch = useDispatch();

    const theme = useSelector((state) => {
        return state.themeSettings.theme
    })


    const fileContent = useSelector((state) => {
        const file = state.files.find(file => file.name === "read.me");

        return file ? file.content : 'File not found';
    });

    const [initialContent, setInitialContent] = useState(fileContent);





    const handleTextChange = (event) => {
        const newContent = event.target.value;
        setInitialContent(newContent);
    }

    const saveFile = () => {
        dispatch(updateFile({ fileName: "read.me", content: initialContent }));

    }

    const undo = () => {
        document.execCommand('undo', false, null)
    }

    const redo = () => {
        document.execCommand('redo', false, null)
    }

    return (
        <DraggableWindow maxDimensions={maxDimensions} label={label} onXClick={onXClick}>
            <div className={`nes-container is-rounded text-editor-buttons ${theme === "dark" ? "is-dark" : "is-light"}`}>
                <div>
                    <button className={`nes-btn ${theme === "dark" ? "is-dark" : ""}`} onClick={undo}>Undo</button>
                    <button className={`nes-btn ${theme === "dark" ? "is-dark" : ""}`} onClick={redo}>Redo</button>
                </div>

                <button style={{ marginRight: 0 }} className={`nes-btn ${theme === "dark" ? "is-dark" : ""}`} onClick={saveFile}>Save</button>
            </div>
            <div className="text-editor">
                <textarea
                    id="text-editor-input"
                    className={`${theme === "dark" ? "white-text" : ""}`}
                    onChange={handleTextChange}
                    type="text"
                    value={initialContent}
                />
            </div>
        </DraggableWindow>
    )
}

export default TextEditor